import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { getAuthToken } from "../../../components/src/NativeWebRouteWrapper/Utils";

export interface UserData {
  data: {
      id: string;
      type: string;
      attributes: {
          activated: boolean;
          country_code: string | null;
          email: string;
          first_name: string | null;
          full_phone_number: string;
          last_name: string | null;
          full_name: string;
          gender: string | null;
          phone_number: string | null;
          type: string;
          created_at: string;
          updated_at: string;
          device_id: string | null;
          unique_auth_id: string;
          user_interests: {
              id: number;
              interest_id: number;
              account_id: number;
              created_at: string;
              updated_at: string;
          }[];
          interest_name: {
              id: number;
              name: string;
              created_by_admin: boolean;
              created_at: string;
              updated_at: string;
              interest_category: string;
              status: string;
          }[];
          photo: string;
          cover_picture: string;
          profession: string | null;
          city: string | null;
          state: string | null;
          country: string;
          partnership_practitioner: boolean | null;
          accredited_partnership_broker: boolean;
          organizational_affilation: string | null;
          preferred_language: string;
          skill_name: {
              id: number;
              name: string;
              created_by_admin: boolean;
              created_at: string;
              updated_at: string;
              interest_category: string;
              status: string;
          }[];
          experience: string | null;
          last_login_at: string | null;
          connections: any[]; // Specify further if connections have a structure
          about_yourself: string | null;
          time_zone: string | null;
          working_language: string | null;
          number_visible: boolean;
      };
  }[];
  meta: {
        total_count: number,
        current_page: number,
        per_page: number,
        total_pages: number
  }
}

interface UserInterest {
  id: number;
  interest_id: number;
  account_id: number;
  created_at: string;
  updated_at: string;
}

interface InterestName {
  id: number;
  name: string;
  created_by_admin: boolean;
  created_at: string;
  updated_at: string;
  interest_category: string;
  status: string;
}

interface SkillName {
  id: number;
  name: string;
  created_by_admin: boolean;
  created_at: string;
  updated_at: string;
  interest_category: string;
  status: string;
}

interface Attributes {
  activated: boolean;
  country_code: string | null;
  email: string;
  first_name: string | null;
  full_phone_number: string;
  last_name: string | null;
  full_name: string;
  gender: string | null;
  phone_number: string | null;
  type: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  user_interests: UserInterest[];
  interest_name: InterestName[];
  photo: string;
  cover_picture: string | null;
  profession: string | null;
  city: string | null;
  state: string | null;
  country: string;
  partnership_practitioner: string | null;
  accredited_partnership_broker: boolean;
  organizational_affilation: string | null;
  preferred_language: string;
  skill_name: SkillName[];
  experience: string | null;
  last_login_at: string | null;
  connections: any[];
  about_yourself: string | null;
  time_zone: string | null;
  working_language: string | null;
  number_visible: boolean;
}

interface MemberDetails {
  id: string;
  type: string;
  attributes: Attributes;
}
export interface MemberProp {
  path: string;
  name: string;
  location: string;
  mission: string;
  affiliation: string[];
  skills: string[];
}

export interface MemberDirectoryListProps {
  membersData: any;
  navigation: any;
  getMemberDetail: any;
  memberDetails: any;
}

export interface MemberDirectoryListState {
  showAll: { [index: number]: boolean };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  membersData?: any;
  getMemberDetail?: any;
  memberDetails?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedLanguage: string;
  selectedCountry: string;
  selectedState: string;
  selectedCity: string;
  selectedRegion: string;
  clearFilters: boolean;
  membersData: any;
  memberId: string;
  memberDetails: MemberDetails,
  token: string;
  searchFeildOpen:boolean;
  searchData: string;
  showAll:any;
  pageNumber: number
  workingLanguageArr: any[]
  countryListArr: any[];
  stateListArr: any[];
  regionListArr: any[];
  cityListArr: any[];
  perPage: number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MemberDirectoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  dropdownRef: any;
  // Customizable Area Start
  getMemberInfo: string = "";
  getMemberDetails: string = "";
  workingLangApiCallId: string = ''
  apiGetCountryList: string = ''
  apiGetStateList: string = ''
  apiGetCityList: string = '';
  GetRegionListAPICall: string = "";
  apiUnreadCountNotification: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedLanguage: "",
      selectedCountry: "",
      selectedState: "",
      selectedCity: "",
      selectedRegion: "",
      membersData: {
        data: [],
        meta: {
          total_count: 0,
          current_page: 0,
          per_page: 0,
          total_pages: 0
        }
      },
      searchFeildOpen: false,
      searchData:'',
      clearFilters: false,
      memberId: "",
      token: String(localStorage.getItem("authToken")),
      showAll:{},
      memberDetails: {
        id: "",
        type: "",
        attributes: {
          activated: false,
          country_code: null,
          email: "",
          first_name: null,
          full_phone_number: "",
          last_name: null,
          full_name: "",
          gender: null,
          phone_number: null,
          type: "",
          created_at: "",
          updated_at: "",
          device_id: null,
          unique_auth_id: "",
          user_interests: [],
          interest_name: [],
          photo: "",
          cover_picture: null,
          profession: null,
          city: null,
          state: null,
          country: "",
          partnership_practitioner: null,
          accredited_partnership_broker: false,
          organizational_affilation: null,
          preferred_language: "",
          skill_name: [],
          experience: null,
          last_login_at: null,
          connections: [],
          about_yourself: null,
          time_zone: null,
          working_language: null,
          number_visible: false,
        },
      },
      pageNumber: 1,
      workingLanguageArr: [],
      countryListArr: [],
      stateListArr: [],
      regionListArr: [],
      cityListArr: [],
      perPage: 10
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      

      if (apiRequestCallId != null) {
         if(apiRequestCallId === this.getMemberInfo){
          this.setState({
            membersData: responseJson
          }); 
        }
      }
      this.apiCallgetMemberDetails(apiRequestCallId, responseJson)
      this.workingLangApiHandler(apiRequestCallId, responseJson)
      this.countryListApiHandler(apiRequestCallId, responseJson)
      this.stateListApiHandler(apiRequestCallId, responseJson)
      this.cityListApiHandler(apiRequestCallId, responseJson)
      this.regionListApiHandler(apiRequestCallId, responseJson);
      this.apiUnreadNotificationCountCaller(apiRequestCallId, responseJson);
  }
    // Customizable Area End
  }

  getUnreadCount = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const header = {
      "Content-Type": "application/json",
      "token": this.state.token
    };
    this.apiUnreadCountNotification = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_notifications/unread_notifications_count`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData( 
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  apiUnreadNotificationCountCaller = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.apiUnreadCountNotification) {
      if (responseJson != null) {
        if (responseJson?.unread_notifications_count) {
          localStorage.setItem("notification_unread_count",responseJson.unread_notifications_count);
        }else{
          localStorage.setItem("notification_unread_count",'');
        }
      }
    }
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  apiCallgetMemberDetails = (apiRequestCallId:any, responseJson:any) => {
    if (apiRequestCallId === this.getMemberDetails) {
      if (responseJson != null) {
        if (responseJson) {
          let postsData = responseJson;
          this.setState({
            memberDetails: postsData
          });
          setStorageData('memberDetailsDatas', JSON.stringify(postsData))
        }
      }
      this.props.navigation.navigate('MemberDetails')
    }
  }

  async componentDidMount(){
    const memberId = await getStorageData("memberId")
    this.setState({
      memberId: memberId
    })
    this.getMemberInfoApiCall()
    this.getWorkingLanguages()
    this.getRegionListArr();
    this.getUnreadCount();
  }

  setLanguage = (event:any, state: any) => {
    this.setState({ selectedLanguage: state === null ? "" : state?.attributes?.language , pageNumber: 1},()=>{
      this.getMemberInfoApiCall()
    })
  }

  setCountry = (event: any, state: any) => {
    this.setState({ selectedCountry: state === null ? "" : state?.name, selectedCity: '', selectedState: '' , pageNumber: 1}, () => {
      this.getStateListArr(this.state.selectedCountry)
      this.getMemberInfoApiCall()
    })
  }

  openSearchTab =()=>{
    this.setState({searchFeildOpen: !this.state.searchFeildOpen})
  }

  setGivenState = (event:any, state: any) => {
    this.setState({ selectedState: state === null ? "" : state?.name, selectedCity: '', pageNumber: 1 },()=>{
      this.getCityListArr(this.state.selectedCountry, this.state.selectedState)
      this.getMemberInfoApiCall()
    })
  }

  setCity = (event:any, state: any) => {
    this.setState({ selectedCity: state === null ? "" : state?.name, pageNumber: 1 },()=>{
      this.getMemberInfoApiCall()
    })
  }

  setRegion = (_: any, state: any) => {
    this.setState({ selectedRegion: state === null ? "" : state?.name, pageNumber: 1, selectedCountry: "", selectedState: "", selectedCity: ""  },() => {
      this.getCountryListArr(this.state.selectedRegion);
      this.getMemberInfoApiCall();
    });
  }

  clearAllFilters = ()=>{
    this.setState({selectedCity: '', 
      selectedLanguage: '',
      selectedCountry: '',
      selectedRegion: '',
      selectedState: '',
      pageNumber: 1,
    },()=>{
      this.getMemberInfoApiCall()
    })
    this.setState(prevState =>({
      clearFilters: !prevState.clearFilters
    }))
  }

  handlePagination = (event:any, value: number) => {
    this.setState({
      pageNumber : value
    },()=>{
      this.getMemberInfoApiCall()
    })
  }  

  getMemberInfoApiCall = () => {
    const header = {
        "Content-Type": "application/json",
        token: getAuthToken()
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMemberInfo = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account/accounts?page=${this.state.pageNumber}&per_page=${this.state.perPage}&search_params[country]=${this.state.selectedCountry}&search_params[city]=${this.state.selectedCity}&search_params[working_language]=${this.state.selectedLanguage}&search_params[region]=${this.state.selectedRegion}&search_params[state]=${this.state.selectedState}&query=${this.state.searchData}`
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return this.getMemberInfo;
};

  handleSearchData = (event:React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({searchData: event.target.value, pageNumber: 1}, () => this.getMemberInfoApiCall())
  }


navigateToMemberDetail = (memberId: string) => {
  setStorageData('dataId', memberId)
  const memberdetails: Message = new Message(getName(MessageEnum.NavigationMessage));
        memberdetails.addData(getName(MessageEnum.NavigationTargetMessage), "MemberDetails");
        memberdetails.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(memberdetails);
};

toggleShowAll = (index: number) => {
  this.setState((prevState) => ({
      showAll: {
          ...prevState.showAll,
          [index]: !prevState.showAll[index]
      }
  }));
};


getWorkingLanguages = () => {
  const header = {
    "token": this.state.token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.workingLangApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.wokringLangArrApiEndPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

addKeyIntoWorkingArrList=(dataArr: any[])=>{
  const newOneData = dataArr.map((ele: any)=>{
    return ({
      ...ele,
      attributes:{
        ...ele.attributes,
        name: ele.attributes.language
      }
    })
  })
  return newOneData;
}

workingLangApiHandler = (apiRequestCallId: any, responseJson: any) => {
  if(apiRequestCallId === this.workingLangApiCallId){
    if (responseJson != null) {
      if (responseJson.data) {
        const updatedData = this.addKeyIntoWorkingArrList(responseJson.data)
        this.setState({workingLanguageArr: updatedData});
      } else {
        this.setState({ workingLanguageArr: [] })
      }
    }
  }
}

getCountryListArr = (region: any) => {
  const headerObj = {
    "Content-Type": configJSON.validationApiContentType,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiGetCountryList = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `accounts/country_code_and_flag/countries_list?region_name=${region}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headerObj)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

countryListApiHandler = (apiRequestCallId: any, responseJson: any) => {
  if(apiRequestCallId === this.apiGetCountryList){
    if (responseJson != null) {
      if (responseJson && responseJson.data) {
        this.setState({ countryListArr: responseJson.data.data.map((item: any) => ({ id: item.attributes.id, name: item.attributes.name })) });
      } else {
        this.setState({ countryListArr: [] })
      }
    }
  }
}

getStateListArr = (country: string) => {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiGetStateList = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `accounts/country_code_and_flag/state_list?country_name=${country}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

stateListApiHandler = (apiRequestCallId: any, responseJson: any) => {
  if(apiRequestCallId === this.apiGetStateList){
    if (responseJson != null) {
      if (responseJson && responseJson.data) {
        this.setState({ stateListArr: responseJson.data });
      } else {
        this.setState({ stateListArr: [] })
      }
    }
  }
}

getCityListArr = (country:string, state: string) => {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiGetCityList = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `accounts/country_code_and_flag/city_list?state_name=${state}&country_name=${country}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

cityListApiHandler = (apiRequestCallId: any, responseJson: any) => {
  if(apiRequestCallId === this.apiGetCityList){
    if (responseJson != null) {
      if (responseJson && responseJson.data) {
        this.setState({ cityListArr: responseJson.data });
      } else {
        this.setState({ cityListArr: [] })
      }
    }
  }
}

getRegionListArr = () => {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    "token": this.state.token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.GetRegionListAPICall = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `accounts/country_code_and_flag/region_list`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

regionListApiHandler = (apiRequestCallId: any, responseJson: any) => {
  if(apiRequestCallId === this.GetRegionListAPICall){
    if (responseJson && responseJson.data) {
      this.setState({ regionListArr: responseJson.data.data.map((item: any) => item.attributes) });
    } else {
      this.setState({ regionListArr: [] })
    }
  }
}
  // Customizable Area End
}
