import React from "react";
import {
    Typography, Avatar, Divider
} from '@material-ui/core';
import '../../assets/Community.scss';

const UserList = ({ state,getUserProfile }: any) => {

    return (
        <div className="user-list">
        { state.userListData && state.userListData.map((item: any, index: number) => {
            console.log(item);
            
            return (
            <>
                <div key={item.id} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", position: "relative" }} >
                    <div key={item.id} onClick={() => getUserProfile(item.id)} test-id="user-list-id" style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                        <Avatar
                            alt="john"
                            src={item.attributes.photo}
                            style={{ width: "56px", height: "56px" }}
                            className="user-list-avatar"
                        />
                        <div>
                            <Typography className="user-list-name">
                                {item.attributes.full_name}
                            </Typography>
                            <Typography className="textSecond">
                                {item.attributes.profession}
                            </Typography>
                        </div>
                    </div>
                </div>
                <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
            </>
            )})
        }
        </div>
 );
};

export default UserList;