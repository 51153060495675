import React from "react";
// Customizable Area Start
import { Typography, Avatar, Box, Grid, Divider, Input, ListItem } from '@material-ui/core';
import moment from "moment";
import { LikeButton } from "../../../../components/src/LikeButton";
import ReturnMedia from "./ReturnMedia.web";
import { MediaGrid, MediaWithGrid, MediaWithoutGrid } from "../../../accountgroups/src/components/media.web";
import SendIcon from '@material-ui/icons/Send';
import {t} from "i18next";

const webStyles = {
  boxStyle: {
    marginBottom: "20px",
  }
}

interface RecentActivitiesProps {
  onUserCommentHandler: (id: number) => boolean;
  list: any[];
  onLikeHandler: (id: number, isLike: boolean, likeId: any) => void;
  usercommentInput?: string,
  onuserCommentChange: (e: any) => void;
  onShareInputHandler: (id: string) => void;
}

export const RecentActivityMedia = ({ length, list, onMediaModalHandler, playVideo, playAudio }: any) => {
  if (length >= 5) {
    return list.slice(0, 4).map((item: any, idx: number) => (
      <MediaGrid mediaArr={list} item={item} idx={idx} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
    ))
  } else if (length === 1) {
    return list.map((item: any, idx: number) => (
      <MediaWithoutGrid mediaArr={list} item={item} idx={idx} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
    ))
  } else {
    return list.map((item: any, idx: number) => (
      <MediaWithGrid mediaArr={list} item={item} idx={idx} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
    ))
  }
}

const RecentActivities = (props: any) => {
  const profile: any = JSON.parse(localStorage.getItem('userInfo') || "{}")
  const { list, commentInput, onCommentChange, onCommentHandler, onLikeHandler, commentBoxVisibilityHandler,
    isCommentEnable, commentIndex, replyIndex, commentActiveIndex, isReplied, repiedIndex, isCommentActive, isReportModal, reportIndex,
    onMediaModalHandler, playVideo, playAudio, onShareInputHandler,
    onRepliedHandler, onReplyHandler, onClickReplyComment, onClickCommentIndex, onReportModalClickHandler, allDownloadHandler, handleConfirmModalOpen } = props
  
    const renderRecentComments = (item: any, index: number) => {
    if (item?.attributes?.recent_comments?.data?.length > 0) {
      return item.attributes.recent_comments.data.map((com: any, idx: number) => (
        <>
          <Box className="recentPostBox">
            <Avatar data-test-id="autherImage" src={com.attributes.author_image} />
            <div className="commentDetail">
              <div className="userNameComment">
                <Typography className="userText" data-test-id="autherName">{com.attributes.author_name}</Typography>
                <Typography className="tag">
                  -
                </Typography>
                <Typography className="time" data-test-id="createdAt">{com.attributes.created_at}</Typography>
              </div>
              <Typography className="time break" data-test-id="commentText">
                {com.attributes.comment}
              </Typography>
              <div className='replied-box'>
                {com.attributes.reply_counts && <Typography data-test-id="repiled-click-id" className="time reply-btn" onClick={() => onRepliedHandler(index, idx)}>{com.attributes.reply_counts} {t('Replied')}</Typography>}
                <Typography data-test-id="reply-btn" className={`time reply-btn ${index} ${idx}`} onClick={() => onReplyHandler(index, idx)}>{t('Reply')}</Typography>
              </div>
            </div>
          </Box>

          {renderRecentReplyCommentInput(item, index, com, idx)}

          {
            commentIndex === index && isReplied && repiedIndex === idx &&
            com.attributes.comments.data.length > 0 && com.attributes.comments.data.map((ele: any) => (
              <Box className="reply-loop-pd recentPostBox">
                <Avatar src={ele.attributes.author_image} data-test-id="autherImage" />
                <div className="commentDetail ">
                  <div className="userNameComment ">
                    <Typography className="userText " data-test-id="autherName">{ele.attributes.author_name}</Typography>
                    <Typography className="tag ">
                      -
                    </Typography>
                    <Typography className="time " data-test-id="createdAt">{ele.attributes.created_at}</Typography>
                  </div>
                  <Typography className="time break" data-test-id="commentText">
                    {ele.attributes.comment}
                  </Typography>
                </div>
              </Box>
            ))
          }
        </>
      ))
    }
  }

  const renderRecentCommentInput = (item: any, index: number) => {
    if ((isCommentEnable && index === commentIndex)) {
      return <Box className="doComment">
        <Input
          id="input-with-icon-adornment"
          placeholder={t('Comment here')}
          data-test-id="comment-2"
          className="inputField "
          value={commentActiveIndex === index ? commentInput : ""}
          onClick={() => onClickCommentIndex(index)}
          onChange={commentActiveIndex === index ? onCommentChange : undefined}
        />
        {commentActiveIndex === index ?
          <SendIcon
            data-test-id="send-id-2"
            onClick={() => onCommentHandler(item?.attributes?.id, null)}
            style={{ cursor: "pointer", margin: "0 10px", fill: commentInput.length > 0 && isCommentActive ? '#5497FF' : "#94A3B8" }}
          /> :
          <SendIcon style={{ cursor: "pointer", margin: "0 10px", fill: "#94A3B8" }} data-test-id="send-id-2" />
        }
      </Box>
    }
  }

  const renderRecentReplyCommentInput = (item: any, index: number, items: any, idx: number) => {
    if (!isCommentEnable && commentIndex === index && replyIndex === idx) {
      return <Box className="doComment">
        <Input
          data-test-id="comment"
          className="inputField"
          id="input-with-icon-adornment"
          placeholder={t('Comment here')}
          value={commentActiveIndex === index ? commentInput : ""}
          onClick={() => onClickReplyComment(index)}
          onChange={commentActiveIndex === index ? onCommentChange : undefined} />
        {commentActiveIndex === index ?
          <SendIcon
            style={{ cursor: "pointer", margin: "0 10px", fill: commentInput.length > 0 && isCommentActive ? '#5497FF' : "#94A3B8" }}
            data-test-id="send-id"
            onClick={() => onCommentHandler(item.attributes.id, items?.attributes?.id)} /> :
          <SendIcon
            data-test-id="send-id"
            style={{ cursor: "pointer", margin: "0 10px", fill: "#94A3B8" }}
          />}
      </Box>
    }
  }
  return (<>
    {list && list.length ? list.map((item: any, i: number) => {
      if(item?.attributes?.post_details?.data) {
      let postDetail = item?.attributes?.post_details?.data
      let mediaArray = [...postDetail.attributes.images, ...postDetail.attributes.videos, ...postDetail.attributes.audios, ...postDetail.attributes.documents]
      let arr = mediaArray
      return (<Box
        className="user-recentPostCard"
        key={`box-post-${item.id}-`}
        sx={webStyles.boxStyle}>
        <ListItem
          alignItems="flex-start"
          style={{
            paddingBlock: "0px",
            height: "40px"
          }}
        >
          <div className="Author_img_main">
            <Avatar
              alt=" fgp yvb"
              src={profile.photo}
              className="Author_img" />
          </div>
          <div style={{
            marginTop: '9px',
            marginLeft: "15px"
          }}
          >
            <Typography
              className="text_Recent_Subtitle1"
              component="p"
              variant="body2"
            >
              {item.attributes.activity_type}
            </Typography>
          </div>

        </ListItem>
        <Divider
          className="user-lineDivider"
        />
        <div
          className="user-recentPostBox">
          <Avatar
            src={postDetail.attributes.author_image}
            alt="user"
          />
          <div className="user-descriptionBox">
            <div className="user-desc">
              <Typography className="user-descTitle"> {postDetail.attributes.author_name} </Typography>
              <div className="tag">
                {postDetail.attributes.created_at}
              </div>
            </div>
            <Typography data-test-id="test-post-id-two" className="user-descText">
              {postDetail.attributes.description.length < 350 ? postDetail.attributes.description
                  : <>{postDetail.attributes.description.substring(0, 350) + "... "}
                      <a data-test-id="more-id" className='seeMoreText'>{t('See more')}</a></>
              }</Typography>
            <div className="recentTags"></div>
          </div>
          <div data-test-id="report-btn" className={`report-btn ${reportIndex} ${item.id} ${isReportModal}`} onClick={() => onReportModalClickHandler(item.id)} style={{ position: "relative", marginTop: 0 }}>
            <svg height="24" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg" width="24"
            >
              <path
                d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                fill="#64748B" />
            </svg>
            {isReportModal && reportIndex == item.id &&
              <Box className='report-modal' test-id="reportbox01" style={{ top: "85%" }}>
                <Typography className='report-text' onClick={() => allDownloadHandler(arr)} data-test-id="download-id">{t('Download')}</Typography>
                <Divider style={{ width: "100%" }} />
                <Typography onClick={() => handleConfirmModalOpen(item.id)} className='report-text' data-test-id="report-id">{t('Report')}</Typography>
              </Box>}
          </div>
        </div>
        <div className='singlePostImageContainer'>
          <Grid style={{ justifyContent: "center", padding: "0 20px" }} container spacing={1}>
            <RecentActivityMedia length={mediaArray.length} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} list={mediaArray} />
          </Grid>
        </div>
        <div className="user-likesCard">
          <div className="user-likesCommentsContainer">
            <Typography className="tag" data-test-id="likesCount"> {postDetail.attributes.post_likes}{postDetail.attributes.post_likes < 2 ? ` ${t('Like')}` : ` ${t('Likes')}`}</Typography>
            <Typography className="tag">-</Typography>
            <Typography className="tag" data-test-id="commentsCount">{postDetail.attributes.post_comments}{postDetail.attributes.post_comments < 2 ? ` ${t('Comment')}` : ` ${t('Comments')}`}</Typography>
          </div>
          <LikeButton isLike={postDetail.attributes.current_user_liked}
            onClick={() => onLikeHandler(postDetail.id, postDetail.attributes.current_user_liked, postDetail.attributes.current_user_liked_id)}
            shareHandler={() => onShareInputHandler(item.id)} commentOnClick={() => commentBoxVisibilityHandler(i)}
            data-test-id="likeBtn" />
          <Divider className="lineDivider" />
        </div>
        {renderRecentComments(postDetail, i)}

        {renderRecentCommentInput(postDetail, i)}
      </Box>
      )
    }
    }) : (
      <div style={{textAlign: 'center', width: '100%'}}>
        <Typography>{t('No posts yet')}</Typography>
      </div>
    )}
  </>
  );
};

// Customizable Area End


export default RecentActivities;
