import React from "react";
// Customizable Area Start
import { Container, Typography, Paper, Box } from '@material-ui/core';

function TermsCondition({ state }: any): JSX.Element {

    return (
        <>
            <main>
                <div
                    className="main-div"
                >
                    <Container className="main-container">
                        <Paper className="paper-main" data-testId="dataIdTestId" style={{ maxWidth: '840px', margin: '0 auto' }}>
                            <div className="grid-container">
                                <div className="img-div">
                                    {
                                        state.image && 
                                        <img
                                            src={state.image}
                                            alt="Left Image"
                                            className="tc-img"
                                        />
                                    }
                                </div>
                                <Typography className="title-heading">Terms of use</Typography>
                                <Box style={{ color: "#475569" }} dangerouslySetInnerHTML={{ __html: state.tcData }} />
                            </div>
                        </Paper>
                    </Container>
                </div>
            </main >
        </>
    );
};
// Customizable Area End

export default TermsCondition;