import React, { useEffect } from "react";
import { Typography, Box, CardMedia, Grid, List, ListItem, ListItemAvatar, Avatar, ListItemText, Modal, Card, styled, CircularProgress } from '@material-ui/core';
import { useInView } from "react-intersection-observer";
import {t} from "i18next";
import Carousel from "react-material-ui-carousel";
import { playImg } from "../../../catalogue/src/assets";
import { noDataFoundImg } from "../assets";

export const GroupMediaData = ({ groupMediaData, type, handleLoadNextAllMedia, isMoreMediaLoading = false }: any) => {
    const [open, setOpen] = React.useState(false);
    const [selectedImage, setSelectedImage] = React.useState('')
    const [currentIndex, setCurrentIndex] = React.useState(0)
    const [currentSelectedItemType, setCurrentSelectedItemType] = React.useState<string>("");
    const [isVideoPlaying, setVideoPlay] = React.useState(false)
    const videoRef = React.useRef<HTMLVideoElement | null | any>(null);
    const [endOfMediaGallery, isEndOfMediaGalleryVisible] = useInView({
        threshold: 0,
    });
    const [isChrome, setChrome] = React.useState(false)

    useEffect(() => {
        if (isEndOfMediaGalleryVisible) {
          handleLoadNextAllMedia?.();
        }
      }, [isEndOfMediaGalleryVisible]);

    useEffect(() => {
        const isChrome = window.navigator.userAgent.includes('Chrome') && !window.navigator.userAgent.includes('Edg')
        setChrome(isChrome)
    },[isChrome])

    const flatData = groupMediaData.map((data: any) => {
        const allTypes = ["videos", "audios", "documents", "images"];
        const typesToRender = type === "all" ? allTypes : [type];
        const mediaArr = typesToRender.map((currentType) => (
            data.attributes[currentType].length > 0 && (
                data.attributes[currentType].map((item: any) => {
                    return item
                }))))
        return mediaArr
    })

    const flattenedData = flatData.flat()     

  const handleOpen = (imgId: number) => {
    setOpen(true);
    const flattenedDataUpdated = flattenedData.filter((data: any) => data).flat()
    
    flattenedDataUpdated.map((item:{id:number, url:string, type: string}, index:number) => {
       if(item.id === imgId) {
            setSelectedImage(item.url)
            setCurrentIndex(index)
            setCurrentSelectedItemType(item.type)
        }
    })
  };

    const handleClose = () => {
        setOpen(false);
    };

    const onNextHandler = () => {
        const flattenedDataUpdated = flattenedData.filter((data: any) => data).flat()
        if(currentIndex + 1 < flattenedDataUpdated.length) {
            setCurrentIndex(currentIndex + 1) 
            flattenedDataUpdated.map((item:{url:string, type: string}, index:number) => {
                if(index === (currentIndex+1)) {
                    setSelectedImage(item.url)
                    setCurrentIndex(index)
                    setCurrentSelectedItemType(item.type)
                }
            })
        }
      };
    
      const onPrevHandler = () => {
        if(currentIndex > 0) { 
            const flattenedDataUpdated = flattenedData.filter((data: any) => data).flat()
            setCurrentIndex(currentIndex - 1) 
            flattenedDataUpdated.map((item:{url:string, type: string}, index:number) => {
                if(index === (currentIndex-1)) {
                    setSelectedImage(item.url)
                    setCurrentIndex(index)
                    setCurrentSelectedItemType(item.type)
                }
            })
        }
      };

      const handleVideoPlayIcon = () => {
        if(videoRef && videoRef.current) {
          setVideoPlay(true)
          videoRef.current.play()
        }
          else setVideoPlay(false)
      }
    
      const handleVideoPauseIcon = () => {
        setVideoPlay(false)
      }

    return (
        <>
        <Grid container spacing={3}>
            {groupMediaData && groupMediaData?.length > 0 ? (
                <>
                {groupMediaData.map((data: any) => {
                    const allTypes = ["videos", "audios", "documents", "images"];
                    const typesToRender = type === "all" ? allTypes : [type];
                    return typesToRender.map((currentType) => (
                    data.attributes[currentType].length > 0 && (
                        data.attributes[currentType].map((item: any, imgIndex: any) => {
                            return(
                            <Grid item xs={12} sm={6} md={4} lg={4} key={imgIndex} style={{}}>
                                <Box style={{boxShadow:"0px 2px 8px 0px #00000014",borderRadius:'8px', height: "100%", display: "flex", justifyContent: "space-between", flexDirection: "column"}}>
                                    {item.type === "image" && (
                                        <div>
                                            <CardMedia
                                                height="140"
                                                component="img"
                                                data-test-id="cardMedia"
                                                style={{
                                                    width: '100%',
                                                    height: '200px',
                                                    margin: '0 auto',
                                                    maxHeight: '200px',
                                                    objectFit: 'cover',
                                                    borderTopLeftRadius:'8px', 
                                                    borderTopRightRadius:'8px',
                                                    cursor:'pointer'
                                                }}
                                                src={item.url}
                                                onClick={() => handleOpen(item.id)}
                                            />
                                        </div>
                                        
                                    )}
                                    {item.type === "video" && 
                                        <Box style={{position:'relative'}}>
                                            <video style={{ height: '200px', width: '100%',borderTopLeftRadius:'8px', borderTopRightRadius:'8px' }}>
                                                <source src={item.url} type="video/mp4" />
                                            </video>
                                                <img data-test-id="videoPlay" src={playImg} onClick={() => handleOpen(item.id)} style={webStyle.videoPlayer}/>
                                        </Box>
                                    }
                                    {item.type === "audio" && (
                                        <audio data-test-id="audio" controls style={{ width: '100%', borderTopLeftRadius:'8px', 
                                            borderTopRightRadius:'8px' }}>
                                            <source src={item.url} type="audio/mpeg" />
                                        </audio>
                                    )}
                                    {item.type === "application" && (
                                        <Box data-test-id="documents" style={{ display: "flex", borderTopLeftRadius:'8px', 
                                            borderTopRightRadius:'8px', cursor:'pointer' }} onClick={() => handleOpen(item.id)}>
                                            <div className="doc-box" style={{ width: "100%", height: "136px"}}>
                                                <Box className='PostImageLefts'>
                                                    <svg
                                                        height="39"
                                                        width="30"
                                                        viewBox="0 0 90.48 122.88"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M35.27.78a3,3,0,0,1,2-.78,1.54,1.54,0,0,1,.47.05h46.2A6.59,6.59,0,0,1,88.56,2a6.52,6.52,0,0,1,1.92,4.64v109.7a6.57,6.57,0,0,1-6.56,6.56H6.67a6.57,6.57,0,0,1-6.56-6.56v-78A3.07,3.07,0,0,1,0,37.56a3.19,3.19,0,0,1,1-2.24L34.9,1a1.5,1.5,0,0,1,.26-.21ZM84.65,6.62a.5.5,0,0,0-.21-.47A.67.67,0,0,0,84,5.94H40.22V31.62a8.89,8.89,0,0,1-8.91,8.91H6.1v75.79a.58.58,0,0,0,.2.47.69.69,0,0,0,.47.21H84a.58.58,0,0,0,.47-.21.73.73,0,0,0,.21-.47V6.62Zm-62,94.73a2.64,2.64,0,0,1,0-5.28h28a2.64,2.64,0,0,1,0,5.28Zm0-17.75a2.64,2.64,0,0,1,0-5.28H65.46a2.64,2.64,0,0,1,0,5.28Zm0-17.76a2.64,2.64,0,0,1,0-5.28H72a2.64,2.64,0,0,1,0,5.28Zm11.7-34.22V10.11L10.11,34.64h21.2a3.16,3.16,0,0,0,2.13-.88,3.06,3.06,0,0,0,.89-2.14Z" />
                                                    </svg>
                                                </Box>
                                            </div>
                                        </Box>
                                    )}
                                    <Box
                                        style={{
                                            backgroundColor: 'background.paper',
                                            width: '100%'
                                        }}
                                    >
                                        <List style={{ border: 'none',
                                            padding:'16px',
                                            gap:'8px' }}>
                                            <ListItem alignItems="flex-start" style={{ padding: '8px 0' }}>
                                                <ListItemAvatar>
                                                    <Avatar src={data.attributes.author_image} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <React.Fragment>
                                                            <Grid container spacing={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                <Grid item xs={8} sm={8} md={8} lg={8} style={{overflow:'hidden',
                                                                        textOverflow:'ellipsis',
                                                                        whiteSpace:'nowrap'}}>
                                                                <Typography
                                                                    component="span"
                                                                    data-test-id="mediaAuthor"
                                                                    variant="body2"
                                                                    style={{
                                                                        lineHeight: '22px',
                                                                        fontSize: '14px',
                                                                        display: 'inline',
                                                                        fontWeight: 700,
                                                                        color: '#475569',
                                                                        fontFamily: 'Comfortaa-Bold',
                                                                        overflow:'hidden',
                                                                        textOverflow:'ellipsis',
                                                                        whiteSpace:'nowrap',
                                                                        wordBreak:'break-word'
                                                                    }}
                                                                >
                                                                    {data.attributes.author_name}
                                                                </Typography>
                                                                </Grid>
                                                                <Grid item xs={4} sm={4} md={4} lg={4} style={{display:'flex', justifyContent:'flex-end'}}>
                                                                    <Typography
                                                                        color="textSecondary"
                                                                        variant="body2"
                                                                        style={{
                                                                            color: '#475569',
                                                                            fontSize: '12px',
                                                                            lineHeight: '18px',
                                                                            fontWeight: 400,
                                                                            fontFamily: 'Nunito-Regular',
                                                                        }}
                                                                    >
                                                                        {data.attributes.created_at}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </React.Fragment>
                                                    }
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography
                                                                data-test-id="mediaDescription"
                                                                component="span"
                                                                variant="body2"
                                                                style={{
                                                                    cursor: 'pointer'
                                                                }} 
                                                                className="trending-desc"
                                                            >
                                                                {data.attributes.description}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        </List>
                                    </Box>
                                </Box>
                            </Grid>
                    )}))))
                })}
                {type === "all" && <span ref={endOfMediaGallery} style={{ opacity: "0" }}>
                    End of block
                </span>}
                </>)
                 : (
                <Grid item xs={12} >
                    <Box
                        style={{
                        height: "400px",
                        margin: "auto",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        gap: "20px",
                        flexDirection: "column",
                        }}
                    >
                        <img
                            data-test-id="resource-image"
                            className="no_data_found"
                            src={noDataFoundImg}
                        />
                        <Typography variant="h6" className="no_data_found_text_list" style={{ marginTop: "0 !important" }}>
                            {t('Ups!... no data found')}
                        </Typography>
                    </Box>
                </Grid>
            )
            }
            {isMoreMediaLoading && <Box padding={"10px 0px"} width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <CircularProgress />
            </Box>}
        </Grid>
         <Modal open={open}>
         <Box>

         {currentSelectedItemType === "image" && 
             <Carousel
                key={selectedImage}
                 test-id="Carousel-image-id"
                 autoPlay={false}
                 navButtonsAlwaysVisible={true}
                 next={onNextHandler}
                 prev={onPrevHandler}
                 className='carouselRoot'
             >
                <img
                src={selectedImage}
                alt={"Media"}
                style={{ borderRadius: '16px',width: "100%", height: "500px", maxHeight:'500px', maxWidth:'100%', objectFit: "contain" }}
                />

             </Carousel>
            }
            {currentSelectedItemType === "video" && 
            <Carousel
                key={selectedImage}
                interval={20}
                test-id="Carousel-video-id"
                autoPlay={false}
                navButtonsAlwaysVisible={true}
                next={onNextHandler}
                prev={onPrevHandler}
                className="carouselVideoRoot"
         >
                <StyledCard key={selectedImage}>
                <VideoBox>
                <video ref={videoRef} data-test-id="playVideo" style={webStyle.previewVideo} controls onPause={handleVideoPauseIcon} onPlay={handleVideoPlayIcon} 
                  ><source src={selectedImage} type='video/mp4' /></video>
                {!isVideoPlaying && isChrome &&
                    <PlayerBox>
                        <img data-test-id="previewPlay" style={{...webStyle.videoImg}} onClick={handleVideoPlayIcon} src={playImg} />
                    </PlayerBox>
                }  
                  </VideoBox>
                  </StyledCard>

             </Carousel>
            }
                    {currentSelectedItemType === "audio" &&
                        <Carousel
                            key={selectedImage}
                            interval={20}
                            test-id="Carousel-audio-id"
                            autoPlay={false}
                            navButtonsAlwaysVisible={true}
                            next={onNextHandler}
                            prev={onPrevHandler}
                            className="carouselAudioRoot"
                        >
                            <StyledCard key={selectedImage}>
                                <audio data-test-id="audio" controls style={{
                                    width: '100%', borderTopLeftRadius: '8px',
                                    borderTopRightRadius: '8px'
                                }}>
                                    <source src={selectedImage} type="audio/mpeg" />
                                </audio>
                            </StyledCard>

                        </Carousel>
                    }
            {currentSelectedItemType === "application" && 
                <Carousel
                key={selectedImage}
                 test-id="Carousel-document-id"
                 interval={20}
                autoPlay={false}
                navButtonsAlwaysVisible
                indicators={false}
                 next={onNextHandler}
                 prev={onPrevHandler}
                 className="carouselDocRoot"
             >
                <DocumentCard key={selectedImage} data-test-id="previewDoc"
                    style={{ borderRadius:'16px',backgroundColor: 'linear-gradient(rgb(231, 239, 252) 100%, rgb(255, 255, 255) 100%', boxShadow: '2px 0px 8px 3px #00000014' }}>
                    <StyledDocumentBox>
                    <iframe src={selectedImage} style={{
                        height: "100%",
                        width:"100%",
                        display:'block'
                        }} frameBorder="0"></iframe>
                </StyledDocumentBox >
                </DocumentCard>
             </Carousel>
            }
             <Box className='carousel-btn'>
                 <Box className='icon-modal' onClick={handleClose}>
                     <svg
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 122.878 122.88"
                         width="15"
                         height="15"
                         fill="#fff"
                     >
                         <path d="M1.426,8.313c-1.901-1.901-1.901-4.984,0-6.886c1.901-1.902,4.984-1.902,6.886,0l53.127,53.127l53.127-53.127 c1.901-1.902,4.984-1.902,6.887,0c1.901,1.901,1.901,4.985,0,6.886L68.324,61.439l53.128,53.128c1.901,1.901,1.901,4.984,0,6.886 c-1.902,1.902-4.985,1.902-6.887,0L61.438,68.326L8.312,121.453c-1.901,1.902-4.984,1.902-6.886,0 c-1.901-1.901-1.901-4.984,0-6.886l53.127-53.128L1.426,8.313L1.426,8.313z" />
                     </svg>
                 </Box>
             </Box>
         </Box>
     </Modal >
     </>
    )
}

const StyledCard = styled(Card)({
    borderRadius: '16px', 
    backgroundColor: 'linear-gradient(rgb(231, 239, 252) 100%, rgb(255, 255, 255) 100%', 
    boxShadow: '2px 0px 8px 3px #00000014' ,
    '&:hover' : {
        backgroundColor: '#F6F0FF',
    },
    '@media (min-width: 1300px)': {
        maxWidth: '800px !important',
        width: '800px'
    },
    '@media (max-width: 1300px)': {
        maxWidth: '600px !important'
    },
    '@media (max-width: 960px)': {
        maxWidth: '500px'
    },
    '@media (max-width: 760px)': {
        maxWidth: '400px'
    }
})

const PlayerBox = styled(Box)({
    position: 'absolute',
    top:'43%',
    left:'47%',
    '@media (max-width: 600px)': {
        left:'43%'
    }
})

const VideoBox = styled(Box)({
    position: 'relative',
    borderRadius: '16px'
})

const webStyle = {
    previewVideo: {
        height: "500px", 
        width: "100%", 
        objectFit: "contain" as const, 
        borderTopLeftRadius: "16px",
        borderTopRightRadius: "16px" 
    },
    videoImg: {
        width: '50px !important',
        height: '50px',
    },
    videoPlayer: {
        position: "absolute" as const,
        top:'40%',
        left:'43%',
        width: '50px !important',
        height: '50px',
        cursor:'pointer'
    }
}

const StyledDocumentBox = styled(Box)({
    position: 'relative',
    left: '0px',
    top: "0px",
    height:'90vh',
    '@media (max-width: 600px)': {
        height:'59vh'
    }
})

const DocumentCard = styled(Card)({
    flexDirection: 'column' as const,
    alignItems: 'center',
    textAlign: 'left',
    maxWidth: '800px',
    width: '800px',
    '@media (max-width: 1160px)': {
        maxWidth: '550px'
    },
    '@media (max-width: 960px)': {
        maxWidth: '450px'
    },
    '@media (max-width: 767px)': {
        maxWidth: '350px'
    },
    '@media (max-width: 460px)': {
        maxWidth: '280px'
    },
}) 