import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getAuthToken } from "../../../components/src/NativeWebRouteWrapper/Utils";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleOpen: any;
  groupMediaData:any;
  group:any;
  groups:any;
  handleJoinGroup:any;
  getGroupDetails:any;
  recentGroups:any;
  handlePostDetails: any;
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  endPoint: string;

  groupMediaData:any;
  pagination: any;

  groupId:any;
  loading: boolean;

  showSnackbar: boolean;
  severity: any;
  message: any;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class GroupMediaController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getGroupMediaApiCallId = "";
  token = getAuthToken();
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "",
      endPoint: "",

      groupMediaData: [],
      pagination: null,

      groupId:"",
      loading: false,

      showSnackbar: false,
      severity: "",
      message: "",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive (from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getGroupMediaApiCallId) {
        if(responseJson.errors){
          this.setState({ loading: false, showSnackbar: true, severity: "error", message: responseJson.errors[0] });
        } else {
          this.setState({ groupMediaData: responseJson.data, loading: false, pagination: responseJson?.meta?.pagination });
        }
      }
  }}

   async componentDidMount() {
    super.componentDidMount();
    window.scroll(0,0)
    const groupId = localStorage.getItem("group_id")
    this.setState({loading: true, endPoint: `group_posts/${groupId}/group_media`, groupId}, () => this.groupMedia(1))
    }

    handleCloseSnackbar = () => {
      this.setState({
        showSnackbar: false,
        severity: "",
        message: "",
      });
    };

   groupMedia = (page: any) => {
    const header = {
          "Content-Type": configJSON.apiContentType,
          "token": this.token
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getGroupMediaApiCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${this.state.endPoint}?page=${page}&per_page=8`
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethod
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
   }

  handlePagination = (event:any, value: number)=>{
    this.groupMedia(value)
  }

   handleEndPoint = (value:string) => {
    this.setState({endPoint:value, loading: true}, () => this.groupMedia(1))
   }

   onDownloadHandler = (item:any) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', item.url, true);
    xhr.responseType = 'blob';
  
    xhr.onload = () => {
      const blob = new Blob([xhr.response], { type: item.type });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = item.filename || 'downloaded-image';
      link.click();
    };
    xhr.send()
}
  // Customizable Area End
}
