import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  Box,
  CardMedia,
  Grid,
  Card,
  CardContent,
  Modal,
} from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { t } from "i18next";
import {
  applicationNoFound,
  audioNoFound,
  EngSubtitle,
  imageNoFound,
  noRecordFound,
  playImg,
} from "../../../catalogue/src/assets";
import { ICard } from "../../../catalogue/src/CatalogueController";
import Carousel from "react-material-ui-carousel";
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment";
import PaginationBlock from "../../../../components/src/Pagination.web";

export const GroupMediaData = ({
  groupMediaData,
  type,
  handlePostDetails,
  onDownloadHandler,
  selectedTab,
  pagination,
  handlePagination
}: any) => {
  const [isHoverButtons, setIsHoverButtons] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewData, setPreviewData] = useState<any>(null);
  const [currentIndex, setCurrentIndex] = useState<any>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState<any>(false);

  const [cardData, setCardData] = useState<any>([]);

  const videoRef = useRef<any>();

  const imageTypeUrl = (cardValue: { url: string; type: string }) => {
    if (cardValue.url !== "" && cardValue.type === "image") {
      return cardValue.url;
    }
    if (cardValue.type === "image") {
      return imageNoFound;
    } else if (cardValue.type === "audio") {
      return audioNoFound;
    } else if (cardValue.type === "document" || cardValue.type === "application") {
      
      return applicationNoFound;
    }
  };

  const iconTypeRender = (cardValue: ICard) => {
    if (cardValue.type === "image") {
      return (
        <ImageIcon
          style={{
            height: "2rem",
            width: "2rem",
            marginRight: "8px",
            marginBottom: "2px",
            color: "#5497FF",
          }}
        />
      );
    } else if (cardValue.type === "video") {
      return (
        <PlayCircleFilledIcon
          style={{
            height: "2rem",
            width: "2rem",
            marginRight: "8px",
            marginBottom: "2px",
            color: "#5497FF",
          }}
        />
      );
    } else if (cardValue.type === "audio") {
      return (
        <svg
          style={{
            height: "2rem",
            width: "2rem",
            marginRight: "8px",
            marginBottom: "2px",
            color: "#5497FF",
          }}
          viewBox="0 0 40 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 32.7539C17.6667 32.7539 19.0833 32.1706 20.25 31.0039C21.4167 29.8372 22 28.4206 22 26.7539V12.7539H28V8.75391H19V21.5539C18.5333 21.2872 18.05 21.0872 17.55 20.9539C17.05 20.8206 16.5333 20.7539 16 20.7539C14.3333 20.7539 12.9167 21.3372 11.75 22.5039C10.5833 23.6706 10 25.0872 10 26.7539C10 28.4206 10.5833 29.8372 11.75 31.0039C12.9167 32.1706 14.3333 32.7539 16 32.7539ZM20 40.7539C17.2333 40.7539 14.6333 40.2289 12.2 39.1789C9.76667 38.1289 7.65 36.7039 5.85 34.9039C4.05 33.1039 2.625 30.9872 1.575 28.5539C0.525 26.1206 0 23.5206 0 20.7539C0 17.9872 0.525 15.3872 1.575 12.9539C2.625 10.5206 4.05 8.40391 5.85 6.60391C7.65 4.80391 9.76667 3.37891 12.2 2.32891C14.6333 1.27891 17.2333 0.753906 20 0.753906C22.7667 0.753906 25.3667 1.27891 27.8 2.32891C30.2333 3.37891 32.35 4.80391 34.15 6.60391C35.95 8.40391 37.375 10.5206 38.425 12.9539C39.475 15.3872 40 17.9872 40 20.7539C40 23.5206 39.475 26.1206 38.425 28.5539C37.375 30.9872 35.95 33.1039 34.15 34.9039C32.35 36.7039 30.2333 38.1289 27.8 39.1789C25.3667 40.2289 22.7667 40.7539 20 40.7539Z"
            fill="#5497FF"
          />
        </svg>
      );
    } else if (cardValue.type === "document" || cardValue.type === "application") {
      return (
        <svg
          style={{
            height: "2rem",
            width: "2rem",
            marginRight: "8px",
            marginBottom: "2px",
            color: "#5497FF",
          }}
          viewBox="0 0 36 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 40C2.9 40 1.95833 39.6083 1.175 38.825C0.391667 38.0417 0 37.1 0 36V8C0 6.9 0.391667 5.95833 1.175 5.175C1.95833 4.39167 2.9 4 4 4H12.4C12.8333 2.8 13.5583 1.83333 14.575 1.1C15.5917 0.366667 16.7333 0 18 0C19.2667 0 20.4083 0.366667 21.425 1.1C22.4417 1.83333 23.1667 2.8 23.6 4H32C33.1 4 34.0417 4.39167 34.825 5.175C35.6083 5.95833 36 6.9 36 8V36C36 37.1 35.6083 38.0417 34.825 38.825C34.0417 39.6083 33.1 40 32 40H4ZM8 32H22V28H8V32ZM8 24H28V20H8V24ZM8 16H28V12H8V16ZM18 6.5C18.4333 6.5 18.7917 6.35833 19.075 6.075C19.3583 5.79167 19.5 5.43333 19.5 5C19.5 4.56667 19.3583 4.20833 19.075 3.925C18.7917 3.64167 18.4333 3.5 18 3.5C17.5667 3.5 17.2083 3.64167 16.925 3.925C16.6417 4.20833 16.5 4.56667 16.5 5C16.5 5.43333 16.6417 5.79167 16.925 6.075C17.2083 6.35833 17.5667 6.5 18 6.5Z"
            fill="#5497FF"
          />
        </svg>
      );
    }
  };

  const handleMouseEnter = (cardValue: any) => {
    setIsHoverButtons(true)
    setSelectedCard(cardValue.id);
  }

  const handleMouseLeave = () => {
    setIsHoverButtons(false)
  }

  const onClosePreview = () => {
    setIsPreviewOpen(false)
  }

  const mediaGrid = () => {
    return <Modal test-id="modal-image-carousel" className='modal-image-carousel' open={isPreviewOpen} onClick={onClosePreview}>
      <div>
        <Carousel
          test-id="Carousel-id"
          indicators={false}
          autoPlay={false}
          interval={20}
          next={onNextHandler}
          prev={onPrevHandler}
          className="CarouselItemResourse"
          navButtonsAlwaysVisible
        >
          {imageManagement(previewData)}
        </Carousel>
        <div className='carousel-btn'>
          <div className='icon-modal' onClick={onClosePreview}>
            <Typography className='modal-btn-text'>
              <CloseIcon />
            </Typography>
          </div>
        </div>
      </div >
    </Modal >
  }

  const imageManagement = (element: ICard) => {
      return <>
        {element.type === 'image' && <Card key={element.attachment_id} className="custom-image-card" 
           style={{ borderRadius: '16px', backgroundColor: 'linear-gradient(rgb(231, 239, 252) 100%, rgb(255, 255, 255) 100%', boxShadow: '2px 0px 8px 3px #00000014' }}>
          <Box
            style={{
              position: 'relative',
              borderRadius: '16px',
              left: '0px',
              top: "0px"
            }}
          >
            <img
              data-test-id="image"
              style={{
                height: "300px",
                borderTopLeftRadius: "16px",
                borderTopRightRadius: "16px",
                width:"100%",
                objectFit: "contain"
              }}
              src={element.url}
            />
          </Box>
          {imageVideoCard()}
        </Card >}
      </>
    }

  const imageVideoCard = () => {
      return (
        <>
        <CardContent style={{ display: 'flex', flexDirection: 'column', padding: '16px',width: 'auto', gap:'8px' }}>
        <Box className="modalTextContainer">
          <Typography variant="body2" className="button-card" style={{ backgroundColor: '#F6F0FF', color: '#475569', borderRadius: '40px', padding: '8px' }}>
            {moment(previewData.updated_at).fromNow()}
          </Typography>
          </Box>
        <Typography variant="body2" color="textSecondary" component="p" style={{
          marginTop: '1px', wordBreak: 'break-word', textOverflow: 'ellipsis',
          overflow: 'hidden', alignItems: 'flex-start', WebkitBoxOrient:'vertical',
          display:'-webkit-box',
          WebkitLineClamp: 2,
          lineHeight:'18px',
          fontSize:'12px'
        }}>
          {handleCheckTextLength()}
        </Typography>
        </CardContent>
        </>
      )
    }

  const handleCheckTextLength = () => {
    return previewData.description?.slice(0, 165)?.length>164? previewData.description?.slice(0, 165)+'...': previewData.description
  }

  const videoGrid = () => {
    return <Modal className='modal-image-carousel' open={isPreviewOpen} >
      <div>
        <Carousel
          interval={20}
          autoPlay={false}
          navButtonsAlwaysVisible
          indicators={false}
          className="CarouselItemResourse"
          next={onNextHandler}
          prev={onPrevHandler}
        >
        {videoManagement(previewData)}
        </Carousel>
        <div className='carousel-btn'>
          <div className='icon-modal' onClick={onClosePreview}>
            <Typography className='modal-btn-text'>
              <CloseIcon />
            </Typography>
          </div>
        </div>
      </div >
    </Modal >
  }

  const videoManagement = (element: ICard) => {
    return <>
      <Card key={element.attachment_id} className="video-custom-card" style={{ borderRadius: '16px', backgroundColor: 'linear-gradient(rgb(231, 239, 252) 100%, rgb(255, 255, 255) 100%', boxShadow: '2px 0px 8px 3px #00000014' }}>
        <Box
          className="video-player" 
          style={{ position: 'relative' }}
        >
          {element.type === "video" &&
            <video ref={videoRef} data-test-id="video" controls onPause={handlePauseIcon} onPlay={handlePlayIcon} style={{ height: "400px", width: "100%", objectFit: "contain", borderTopLeftRadius: "16px", borderTopRightRadius: "16px" }}>
              <source src={element.url} type='video/mp4' />
              <track src={EngSubtitle} label="English" kind="subtitles" srcLang="en" default />
            </video>
          }
          {element.type === "audio" &&
            <audio data-test-id="audio" controls style={{ width: '100%', height:'40px' }} onPlay={() => playAudio()}>
              <source src={element.url} type="audio/mpeg" />
            </audio>
          }
          {!isVideoPlaying && element.type === "video" &&
            <div className="player-controls">
                <img onClick={handlePlayIcon} src={playImg} />
            </div>
          }
        </Box>
        {imageVideoCard()}
      </Card >
    </>
  }

  const playAudio = () => {
    document.addEventListener('play', function (e) {
      const audio = document.getElementsByTagName('audio');
      for (let item of audio) {
        if (item != e.target) {
          item.pause();
        }
      }
    }, true);
  };

  const handlePlayIcon = () => {
    if(videoRef && videoRef.current) {
      setIsVideoPlaying(true)
      videoRef.current.play()
    } else {
      setIsVideoPlaying(false)
    }
  }

  const handlePauseIcon = () => {
    setIsVideoPlaying(false)
  }

  const documentGrid = () => {
    return <Modal className='modal-image-carousel' open={isPreviewOpen} onClick={onClosePreview} >
      <div>
        <Carousel
          interval={20}
          autoPlay={false}
          navButtonsAlwaysVisible
          indicators={false}
          className="CarouselItemResourse"
          next={onNextHandler}
          prev={onPrevHandler}
        >
          {documentManagement(previewData)}
        </Carousel>
        <div className='carousel-btn'>
          <div className='icon-modal' onClick={onClosePreview}>
            <Typography className='modal-btn-text'>
              <CloseIcon />
            </Typography>
          </div>
        </div>
      </div >
    </Modal >
  }

  const documentManagement = (element:ICard) => {
    return(
        <>
        <Card key={element.attachment_id} className="custom-image-card" data-test-id="documentPreview"
           style={{ backgroundColor: 'linear-gradient(rgb(231, 239, 252) 100%, rgb(255, 255, 255) 100%', boxShadow: '2px 0px 8px 3px #00000014' }}>
          <Box
            style={{
              position: 'relative',
              left: '0px',
              top: "0px",
              height:'90vh'
            }}
          >
            <iframe
              style={{
                height: "100%",
                borderTopLeftRadius: "16px",
                borderTopRightRadius: "16px",
                width:"100%",
                display:'block'
              }}
              src={element.url}
              frameBorder={0}
            ></iframe>
        </Box >
        </Card>
        </>
      )
    }

  const onNextHandler = () => {
    if(currentIndex + 1 < cardData.length) {
      setCurrentIndex(currentIndex + 1)
      cardData.forEach((item:any, index:number) => {
        if(index === (currentIndex+1)) {
          setPreviewData(item)
        }
      });
    }
  };
  
  const onPrevHandler = () => {
    if(currentIndex > 0) { 
      setCurrentIndex(currentIndex - 1)
      cardData.forEach((item:any, index:number) => {
        if(index === (currentIndex-1)) {
          setPreviewData(item)
        }
      });
    }
  };
  

  const handlePreviewOpen = (cardValue: any, card: any) => {
    setIsPreviewOpen(true);
    setPreviewData({...card, updated_at: cardValue.attributes.updated_at, description: cardValue.attributes.description })
    setSelectedCard(card);
    setCurrentIndex(cardData.findIndex((item: any) => item.id === card.id))
  }

  useEffect(() => {
    if(groupMediaData?.length) {
      const allTypes = ["videos", "audios", "documents", "images"];
      const typesToRender = type === "all" ? allTypes : [type];

      let cardData: any = [];
      groupMediaData.forEach((data: any) => {
        typesToRender.forEach((currentType) => {
          cardData = [...cardData, ...data?.attributes?.[currentType].map((item: any) => ({...item, updated_at: data.attributes.updated_at, description: data.attributes.description }))];
          setCardData(cardData);
        })
      })
    }
  }, [groupMediaData])

  return (
    <Grid container spacing={3}>
      {previewData?.type === 'image' && mediaGrid()}
      {(previewData?.type === 'video' || previewData?.type === 'audio') && videoGrid()}
      {(previewData?.type === 'document' || previewData?.type === 'application') && documentGrid()}

      {groupMediaData && groupMediaData?.length > 0 ? (
        groupMediaData.map((data: any) =>{
            const allTypes = ["videos", "audios", "documents", "images"];
            const typesToRender = type === "all" ? allTypes : [type];
            return typesToRender.map((currentType) => {
              return (
              data?.attributes?.[currentType]?.length > 0 &&
              data?.attributes?.[currentType].map((item: any, index: any) => {
                return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  style={{ height: "320px" }}
                >
                  <Card
                    key={item.id}
                    data-testid="handleMouseEvent"
                    className="custom-card"
                    style={{
                      height: "auto",
                      borderRadius: "8px",
                      backgroundColor:
                        "linear-gradient(rgb(231, 239, 252) 100%, rgb(255, 255, 255) 100%",
                      boxShadow: "2px 0px 8px 3px #00000014",
                      justifyContent: "center",
                    }}
                    onMouseOver={() => handleMouseEnter(item)}
                    onMouseLeave={() => handleMouseLeave()}
                  >
                    <Box style={{ position: "relative", borderRadius: "8px", left: "0px", top: "0px" }}>
                      {isHoverButtons && selectedCard === item.id && (
                        <Box zIndex={9999999}>
                          <Box zIndex={9999999} position={"absolute"} top={"0px"} left={"0px"} width={'100%'} display={"flex"} alignItems={'center'} justifyContent={'center'} height={"100%"} >
                            <svg
                              data-test-id="download-test-id"
                              style={{ color: 'white', cursor: 'pointer', margin: '16px' }}
                              onClick={() => onDownloadHandler(item)}
                              width="32" height="32" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.0002 16.332L4.3335 9.66537L6.20016 7.73203L9.66683 11.1987V0.332031H12.3335V11.1987L15.8002 7.73203L17.6668 9.66537L11.0002 16.332ZM3.00016 21.6654C2.26683 21.6654 1.63905 21.4043 1.11683 20.882C0.594607 20.3598 0.333496 19.732 0.333496 18.9987V14.9987H3.00016V18.9987H19.0002V14.9987H21.6668V18.9987C21.6668 19.732 21.4057 20.3598 20.8835 20.882C20.3613 21.4043 19.7335 21.6654 19.0002 21.6654H3.00016Z" fill="white" />
                            </svg>
                            <svg style={{ color: 'white',  cursor: 'pointer' }} 
                            data-test-id="preview-test-id"
                            onClick={() => handlePreviewOpen(data, item)} 
                            width="32" height="32" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M24.6665 6.9987V2.9987H20.6665V0.332031H24.6665C25.3998 0.332031 26.0276 0.593142 26.5498 1.11536C27.0721 1.63759 27.3332 2.26536 27.3332 2.9987V6.9987H24.6665ZM0.666504 6.9987V2.9987C0.666504 2.26536 0.927615 1.63759 1.44984 1.11536C1.97206 0.593142 2.59984 0.332031 3.33317 0.332031H7.33317V2.9987H3.33317V6.9987H0.666504ZM20.6665 21.6654V18.9987H24.6665V14.9987H27.3332V18.9987C27.3332 19.732 27.0721 20.3598 26.5498 20.882C26.0276 21.4043 25.3998 21.6654 24.6665 21.6654H20.6665ZM3.33317 21.6654C2.59984 21.6654 1.97206 21.4043 1.44984 20.882C0.927615 20.3598 0.666504 19.732 0.666504 18.9987V14.9987H3.33317V18.9987H7.33317V21.6654H3.33317ZM5.99984 16.332V5.66536H21.9998V16.332H5.99984ZM8.6665 13.6654H19.3332V8.33203H8.6665V13.6654Z" fill="white" />
                            </svg>
                          </Box>
                        </Box>
                      )}
                              
                      {item.type !== "video" && (
                        <CardMedia
                          component="img"
                          style={{
                            height: "200px",
                            borderTopLeftRadius: "8px",
                            borderTopRightRadius: "8px",
                          }}
                          alt={`Image ${index + 1}`}
                          image={imageTypeUrl(item)}
                          title={`Image ${index + 1}`}
                        />
                      )}
                      {item.type === "video" && (
                        <Box style={{ position: "relative", zIndex: 0 }}>
                          <video
                            style={{
                              height: "200px",
                              width: "100%",
                              borderTopLeftRadius: "8px",
                              borderTopRightRadius: "8px",
                              zIndex: 0
                            }}
                          >
                            <source src={item.url} type="video/mp4" />
                          </video>
                        </Box>
                      )}
                    </Box>
                    <CardContent
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "16px",
                        width: "auto",
                        gap: "8px",
                      }}
                    >
                      <Box
                        className="icon-lang-container"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px"
                        }}>
                        {iconTypeRender(item)}
                        <Typography
                          variant="h6"
                          style={{
                            color: "#475569",
                            fontWeight: 600,
                            fontSize: "14px",
                          }}
                        >{data.attributes.author_name}</Typography>
                        </Box>
                        <Typography
                          variant="body2"
                          className="button-card"
                          style={{
                            backgroundColor: "#F6F0FF",
                            color: "#475569",
                            borderRadius: "40px",
                            padding: "6px 8px",
                          }}
                        >
                          {moment(data.attributes.updated_at).fromNow()}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        style={{
                          width: "100%",
                          wordBreak: "break-word",
                          textOverflow: "ellipsis",
                          WebkitLineClamp: 2,
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          display: "-webkit-box",
                          minHeight: "2.2rem",
                          lineHeight: "18px",
                        }}
                      >
                        {data.attributes.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )})
            )})  
        })
      ) : (
        <Grid item xs={12}>
          <Grid
            style={{
              height: "400px",
              margin: "auto",
              alignItems: "center",
              display: "grid",
              justifyContent: "center",
              gap: "120px",
            }}
          >
            <img
              data-test-id="resource-image"
              className="no_data_found"
              src={noRecordFound}
            />
            <Typography variant="h6" className="no_data_found_text">
              {t("Ups!... no data found")}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        {cardData.length > 0 && pagination && <PaginationBlock 
          data-test-id="pagination-resource-hub" 
          page={pagination?.current_page} 
          totalCount={pagination?.total_pages}
          handlePagination={handlePagination} 
        />}
      </Grid>
    </Grid>
  );
};
