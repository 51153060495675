import React from 'react';
import {
    Avatar,
    Box,
    Divider,
    Grid,
    Link,
    Typography,
} from "@material-ui/core";
import TranslationCore from './TranslationCore.web'
import { t } from 'i18next';
import { groupIcon } from '../assets';

const AllPostDetails = (
    {
        state,
        authorId,
        onLikeHandler,
        onEditHandler,
        onReplyHandler,
        onCommentChange,
        onDeleteHandler,
        onRepliedHandler,
        onCommentHandler,
        handlePostDetails,
        allDownloadHandler,
        onShareInputHandler,
        onClickCommentIndex,
        onClickReplyComment,
        onCommentDeleteHandler,
        handleConfirmModalOpen,
        onReportModalClickHandler,
        commentBoxVisibilityHandler,
        onMediaModalHandler,
        handleTransBackToOriginal,
        handleTranslation,
        playVideo,
        playAudio,
        onrhDescriptionChange,
        onrhLanguageChange,
        handleOnShareToResourceHub,
        handleStringHashTags,
    }: any) => {
    const ReportMenu = (arr: any, item: any, index: number) => {
        return (
            <>
                {state.isReportModal && state.reportIndex === item.id &&
                    <Box className='new-report-modal'>
                        <Typography test-id="down-click-id" className='report-text' onClick={(e) => {e.stopPropagation(); allDownloadHandler(arr)}}>{t('Download')}</Typography>
                        {
                            item.attributes.author_id == authorId &&
                            <>
                                <Divider style={{ width: "100%" }} />
                                <Typography test-id="delete-id" className='report-text' onClick={(e) => {e.stopPropagation(); onDeleteHandler(item.attributes.id)}}>{t("Delete")}</Typography>
                                <Divider style={{ width: "100%" }} />
                                <Typography test-id="edit-id" className='report-text' onClick={(e) => {e.stopPropagation(); onEditHandler(item, index)}}>{t('Edit')}</Typography>
                            </>
                        }
                        {
                            item.attributes.author_id != authorId && 
                            <>
                                <Divider style={{ width: "100%" }} />
                                <Typography test-id="report-click-id" onClick={(e) => {e.stopPropagation(); handleConfirmModalOpen(item.id)}} className='report-text'>{t('Report')}</Typography>
                            </>
                        }
                    </Box>
                }
            </>
        )
    }
   
    return (
        <>
            {state.postsData.map((item: any, index: number) => {
                let mediaArr = [...item.attributes.images, ...item.attributes.videos, ...item.attributes.audios, ...item.attributes.documents]
                let arr = mediaArr
                let filter = []
                filter = state.translationArray?.filter((i: any) => i.id == item.id).map((i: any) => i.data)
                const description = item.attributes.description;
                const words = description.split(' ');
                return (
                    <>
                        <Box className="recentPostCards" key={item.id}>
                            <Box className="post_container" onClick={(e) => {e.stopPropagation();handlePostDetails(item.id);}}>
                                <Avatar className="post_avatar_image" src={item.attributes.author_image}/>
                                <Grid container className="post_main_info" xs={8} sm={10} md={10} lg={10} xl={10}>
                                    <Box style={{ display: 'flex', alignItems: 'center' }} >
                                        <Typography className="post_author_name">
                                            {`${item.attributes.author_name}`}
                                        </Typography>
                                        {authorId == item.attributes.author_id && <Typography
                                            component="span"
                                            variant="body2"
                                            className="author_text"
                                        >
                                            {`(${t('Author')})`}
                                        </Typography>}
                                    </Box>
                                    {item.attributes.group_name && (
                                        <div
                                                className="recentTags reply-btn"
                                                test-id="group-post-box"
                                                style={{ margin: "8px 0", border: "1px solid #5497FF", width: "fit-content", borderRadius: "50px", padding: "2px 10px", alignItems: "center", display: 'flex', cursor:'pointer' }}
                                                onClick={(e) => {e.stopPropagation(); window.open(`/AccountGroups/${item.attributes.group_id}`, "_self");}}
                                        >
                                                <img src={groupIcon} alt="" width="20px" height="20px" />
                                                <Typography className="group-tag-text">
                                                    {item.attributes.group_name}
                                                </Typography>
                                        </div>
                                    )}
                                    <div className='post_truncated_text'>
                                        {words.map((word: string, index: number) => {
                                            const isHashtag = word.startsWith('#');
                                            return (
                                                <span
                                                    key={index}
                                                    className={isHashtag ? 'descText_hash' : 'descText_nonHash'}
                                                    style={{ marginRight: '4px' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (isHashtag) {
                                                            handleStringHashTags(word);
                                                        }
                                                    }}
                                                    test-id="hash-tag-description"
                                                >
                                                    {word}
                                                </span>
                                            );
                                        })}
                                    </div>
                                    <Box className='translationBox'>
                                    {
                                        item.attributes.description?.length > 0 ?
                                            <TranslationCore item={item} filter={filter} handleTranslation={handleTranslation} handleTransBackToOriginal={handleTransBackToOriginal} />
                                            : null
                                    }
                                    </Box>
                                </Grid>
                                <Grid container className="post_created_container" xs={4} sm={2} md={2} lg={2} xl={2} >
                                    <Typography className="post_created_at_text">{item.attributes.created_at}</Typography>
                                    <div data-test-id="report-btn" className={`post_three_dots`} 
                                        onClick={(e:any) => {
                                            e.stopPropagation()
                                            onReportModalClickHandler(item.id)
                                        }} 
                                        style={{ position: "relative" }}>
                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.09863 10.3516C4.99863 10.3516 4.09863 11.2516 4.09863 12.3516C4.09863 13.4516 4.99863 14.3516 6.09863 14.3516C7.19863 14.3516 8.09863 13.4516 8.09863 12.3516C8.09863 11.2516 7.19863 10.3516 6.09863 10.3516ZM18.0986 10.3516C16.9986 10.3516 16.0986 11.2516 16.0986 12.3516C16.0986 13.4516 16.9986 14.3516 18.0986 14.3516C19.1986 14.3516 20.0986 13.4516 20.0986 12.3516C20.0986 11.2516 19.1986 10.3516 18.0986 10.3516ZM12.0986 10.3516C10.9986 10.3516 10.0986 11.2516 10.0986 12.3516C10.0986 13.4516 10.9986 14.3516 12.0986 14.3516C13.1986 14.3516 14.0986 13.4516 14.0986 12.3516C14.0986 11.2516 13.1986 10.3516 12.0986 10.3516Z" fill="#64748B" />
                                        </svg>
                                    </div>
                                    {ReportMenu(arr, item, index)}
                                </Grid>
                            </Box>
                        </Box>
                    </>
                )
            })}
        </>
    )
}

export default AllPostDetails