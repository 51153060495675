import React, { useState } from 'react';
import {
    Avatar,
    Badge,
    Modal,
    Typography,
    Grid,
    Box,
} from '@material-ui/core';
import { t } from 'i18next';
import '../../assets/RequestManagement.scss'
import RejectRequestModal from './RejectRequestModal.web';
import HoldRequestModal from './HoldRequestModal.web';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 588,
    borderRadius: '8px',
    outline: 'none',
    minWidth: 457
};

interface PendingRequestModalTypes {
    open: boolean
    modalCloseFn: any
    membersList: any[]
    navigation: any
    rejectPendingRequestFunction: Function
    acceptPendingRequestFunction: Function
    createHoldMessageRequest: Function
    messageList: any[]
    groupId: any
}

const PendingRequestModal = (props: PendingRequestModalTypes) => {
    const [open, setOpen] = useState(false);
    const [isHoldRequestOpen, setHoldRequestOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [member, setMember] = useState(null);

    const handleClose = () => {
        setOpen(false)
    };

    const handleToggleHoldRequest = () => {
        setHoldRequestOpen((prev) => !prev);
    };

    return (
        <>
        <Modal open={props.open} onClose={props.modalCloseFn} data-test-id='pending-modal-close-click'>
            <Box sx={style} className='pending_modal_box'>
                <Grid item xs={12} lg={12} sm={12} md={12}>
                    <Grid className='pending_box'>
                        <Typography variant="h6" className='pending_request_text'>
                            {t(`Pending Request`)}
                        </Typography>
                        <div onClick={props.modalCloseFn} style={{ cursor: 'pointer' }}>
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.2005 4.30713C12.0759 4.18229 11.9068 4.11214 11.7305 4.11214C11.5541 4.11214 11.385 4.18229 11.2605 4.30713L8.00047 7.56046L4.74047 4.30046C4.61591 4.17562 4.44681 4.10547 4.27047 4.10547C4.09412 4.10547 3.92502 4.17562 3.80047 4.30046C3.54047 4.56046 3.54047 4.98046 3.80047 5.24046L7.06047 8.50046L3.80047 11.7605C3.54047 12.0205 3.54047 12.4405 3.80047 12.7005C4.06047 12.9605 4.48047 12.9605 4.74047 12.7005L8.00047 9.44046L11.2605 12.7005C11.5205 12.9605 11.9405 12.9605 12.2005 12.7005C12.4605 12.4405 12.4605 12.0205 12.2005 11.7605L8.94047 8.50046L12.2005 5.24046C12.4538 4.98713 12.4538 4.56046 12.2005 4.30713Z" fill="white" />
                            </svg>
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={12} sm={12} md={12}>
                    <Box className="requests-section">
                        {props.membersList.map((member: any, index: number) => {
                            return (
                                <>
                                    <Box className='member_container'>
                                        <Box className='member_basic_info'>
                                            <Avatar
                                                src={member.account_photo}
                                                alt="Member Picture"
                                                className='request_avatar'
                                            />
                                            <Typography variant="h6" className='member_name_text'>
                                                {member.user_name}
                                            </Typography>
                                        </Box>
                                        <Box className='member_basic_info'>
                                            <Badge className='tick_icon_container' onClick={()=>{props.acceptPendingRequestFunction(member.user_id)}}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <mask id="mask0_11586_47003" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                                        <rect width="24" height="24" fill="#D9D9D9" />
                                                    </mask>
                                                    <g mask="url(#mask0_11586_47003)">
                                                        <path d="M9.54961 17.9996L3.84961 12.2996L5.27461 10.8746L9.54961 15.1496L18.7246 5.97461L20.1496 7.39961L9.54961 17.9996Z" fill="#059669" />
                                                    </g>
                                                </svg>
                                            </Badge>
                                            <Badge className='cross_icon_container' onClick={() => {
                                                setCurrentIndex(index)
                                                setOpen(true)
                                            }}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18.2997 5.71069C18.1129 5.52344 17.8592 5.4182 17.5947 5.4182C17.3302 5.4182 17.0766 5.52344 16.8897 5.71069L11.9997 10.5907L7.10973 5.70069C6.9229 5.51344 6.66924 5.4082 6.40473 5.4082C6.14021 5.4082 5.88656 5.51344 5.69973 5.70069C5.30973 6.09069 5.30973 6.72069 5.69973 7.11069L10.5897 12.0007L5.69973 16.8907C5.30973 17.2807 5.30973 17.9107 5.69973 18.3007C6.08973 18.6907 6.71973 18.6907 7.10973 18.3007L11.9997 13.4107L16.8897 18.3007C17.2797 18.6907 17.9097 18.6907 18.2997 18.3007C18.6897 17.9107 18.6897 17.2807 18.2997 16.8907L13.4097 12.0007L18.2997 7.11069C18.6797 6.73069 18.6797 6.09069 18.2997 5.71069Z" fill="#F87171" />
                                                </svg>
                                            </Badge>
                                            <Badge className='hold_icon_container' data-test-id="open-hold-modal" onClick={() => {
                                                props.createHoldMessageRequest({ group_id: props.groupId, user_id: member.user_id, content: "" })
                                                setCurrentIndex(index)
                                                handleToggleHoldRequest()
                                                setMember(member)
                                                props.modalCloseFn();
                                            }}>
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <mask id="mask0_11586_47017" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="22">
                                                        <rect width="22" height="22" fill="#D9D9D9" />
                                                    </mask>
                                                    <g mask="url(#mask0_11586_47017)">
                                                        <path d="M11.0003 10.9993V1.83268C11.0003 1.57296 11.0882 1.35525 11.2639 1.17956C11.4396 1.00386 11.6573 0.916016 11.917 0.916016C12.1767 0.916016 12.3944 1.00386 12.5701 1.17956C12.7458 1.35525 12.8337 1.57296 12.8337 1.83268V10.9993H11.0003ZM7.33366 10.9993V2.74935C7.33366 2.48963 7.42151 2.27192 7.5972 2.09622C7.77289 1.92053 7.9906 1.83268 8.25033 1.83268C8.51005 1.83268 8.72776 1.92053 8.90345 2.09622C9.07914 2.27192 9.16699 2.48963 9.16699 2.74935V10.9993H7.33366ZM11.4587 21.0827C9.28921 21.0827 7.44824 20.3264 5.93574 18.8139C4.42324 17.3014 3.66699 15.4605 3.66699 13.291V4.58268C3.66699 4.32296 3.75484 4.10525 3.93053 3.92956C4.10623 3.75386 4.32394 3.66602 4.58366 3.66602C4.84338 3.66602 5.06109 3.75386 5.23678 3.92956C5.41248 4.10525 5.50033 4.32296 5.50033 4.58268V13.291C5.50033 14.9563 6.07706 16.3657 7.23053 17.5191C8.38401 18.6726 9.79338 19.2493 11.4587 19.2493C13.1239 19.2493 14.5333 18.6726 15.6868 17.5191C16.8403 16.3657 17.417 14.9563 17.417 13.291V10.0827C17.1573 10.0827 16.9396 10.1705 16.7639 10.3462C16.5882 10.5219 16.5003 10.7396 16.5003 10.9993V14.666H13.7503C13.2462 14.666 12.8146 14.8455 12.4555 15.2046C12.0965 15.5636 11.917 15.9952 11.917 16.4993V17.416H10.0837V16.4993C10.0837 15.491 10.4427 14.6278 11.1607 13.9098C11.8788 13.1917 12.742 12.8327 13.7503 12.8327H14.667V3.66602C14.667 3.40629 14.7548 3.18859 14.9305 3.01289C15.1062 2.8372 15.3239 2.74935 15.5837 2.74935C15.8434 2.74935 16.0611 2.8372 16.2368 3.01289C16.4125 3.18859 16.5003 3.40629 16.5003 3.66602V8.40977C16.6531 8.36393 16.8021 8.32574 16.9472 8.29518C17.0923 8.26463 17.2489 8.24935 17.417 8.24935H19.2503V13.291C19.2503 15.4605 18.4941 17.3014 16.9816 18.8139C15.4691 20.3264 13.6281 21.0827 11.4587 21.0827Z" fill="#5497FF" />
                                                    </g>
                                                </svg>
                                                {Array.isArray(member.coversations) && member.coversations.length > 0 && <Box className="pending_conversation_container">
                                                    <Typography
                                                        className="pending_conversation_number"
                                                    >
                                                        {`${member.coversations.length}`}
                                                    </Typography>
                                                </Box>}
                                            </Badge>
                                        </Box>
                                    </Box>
                                    {index !== props.membersList.length - 1 && <div className='divider_container'>
                                        <div className='divider' />
                                    </div>}
                                    {index === currentIndex && <RejectRequestModal 
                                        open={open} 
                                        modalCloseFn={handleClose} 
                                        sendClick={props.rejectPendingRequestFunction} 
                                        user={member}/>}

                                </>
                            )
                        })}
                    </Box>
                </Grid>

            </Box>
        </Modal>
        <HoldRequestModal
            open={isHoldRequestOpen}
            modalCloseFn={handleToggleHoldRequest}
            userInfo={member}
            conversation={props.messageList}
            createHoldMessageRequest={props.createHoldMessageRequest}
            groupId={props.groupId}
        />
        </>
    );
}

export default PendingRequestModal