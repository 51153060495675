import React from "react";

const FilterTranslation = ({ filter, description, handleStringHashTags }: any) => {
    return (
        filter?.length ? filter[0] : description.split(' ').map((word: string, index: number) => {
            const isHashtag = word.startsWith('#');
            return (
                <span
                    key={index}
                    className={isHashtag ? 'descText_hash' : 'descText_nonHash'}
                    style={{ marginRight: '4px' }}
                    test-id="hash-tag-description"
                    onClick={(e) => {
                        e.stopPropagation();
                        if (isHashtag) {
                            handleStringHashTags(word);
                        }
                    }}
                >
                    {word}
                </span>
            );
        })
    );
};

export default FilterTranslation;