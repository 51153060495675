import React, { useState } from 'react';
import {
    Avatar,
    Button,
    Badge,
    Modal,
    Typography,
    Grid,
    Box,
    TextField
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { t } from 'i18next';
import '../../assets/RequestManagement.scss'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 588,
    borderRadius: '8px',
    outline: 'none',
};

interface UserTypes {
    user_id: number
    user_name: string
    user_email: string
    account_photo: string
}

interface DeclineRequestModalTypes {
    open: boolean
    modalCloseFn: any
    sendClick: Function
    user: UserTypes
}

const RejectRequestModal = (props: DeclineRequestModalTypes) => {
    const [reason, setReason] = useState('')
    return (
        <Modal open={props.open}
                        onClose={props.modalCloseFn} 
                        data-test-id='modal-close-click'>
                        <Box sx={style} className='pvt_msg_box'>
                            <Grid item xs={12} lg={12} sm={12} md={12}>
                                <Box className='pvt_head_box'>
                                    <Typography variant="h6" className='pvt_head_title'>
                                        {t(`Decline Request`)}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={12} sm={12} md={12}>
                                <Box
                                className="dialog_cont"
                            
                                >
                                    <Avatar
                                        src={props.user.account_photo}
                                        style={{ height: '48px', width: '48px', marginBottom: 'auto' }}
                                    />
                                    <TextField
                                        className="pvt_chat_box"
                                        placeholder="Reason for declining the request"
                                        multiline
                                        minRows={3}
                                        maxRows={3}
                                        value={reason}
                                        onChange={(e: any)=> setReason(e.target.value)}
                                        InputProps={{
                                            inputProps: {
                                                maxLength: 3000,
                                            }
                                        }}
                                    />
                                    </Box>
                                <Grid container className="pvt_counter_container">
                                    <Typography variant="h6" className='pvt_counter_text'>
                                        {`${3000 - reason.length}`}
                                    </Typography>
                                </Grid>
                                <Grid container className="cancel_send_button_cont">
                                        <Grid item style={{ width: '48%' }}>
                                            <Button
                                                type="button"
                                                style={{
                                                    width: '100%',
                                                    fontFamily: "Nunito-Regular",
                                                    fontSize: '16px',
                                                    color: '#5497FF',
                                                    fontWeight: 800,
                                                    lineHeight: '24px',
                                                    boxShadow: 'none'
                                                }}
                                                onClick={props.modalCloseFn}
                                                className="pvt_cancel_btn"
                                                variant="outlined"
                                                color="primary">{t('CANCEL')}
                                            </Button>
                                        </Grid>
                                        <Grid item style={{ width: '48%' }}>
                                            <Button
                                                type="button"
                                                style={{
                                                    width: '100%',
                                                    fontFamily: "Nunito-Regular",
                                                    fontSize: '16px',
                                                    color: '#FFFFFF',
                                                    fontWeight: 800,
                                                    lineHeight: '24px',
                                                    boxShadow: 'none'
                                                }}
                                                onClick={()=>{props.sendClick(props.user.user_id,reason); 
                                                    props.modalCloseFn()
                                                }}
                                                className={(reason.length === 0) ? "pvt_send_btn_disable" : "pvt_send_btn_enable"}
                                                variant="contained"
                                                disabled={reason.length === 0}
                                                color="primary">{t('SEND')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                            </Grid>

                        </Box>
                    </Modal>
    );
}

export default RejectRequestModal