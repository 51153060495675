import React from "react";
// Customizable Area Start
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box
} from "@material-ui/core";
import Header from '../../../components/src/Header.web';
import "../assets/index.scss";
import PaginationBlock from '../../../components/src/Pagination.web';
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import {t} from "i18next";
// Customizable Area End
import NotificationsController, {
  Props,
} from "./NotificationsController";
import HoldRequestModal from "../../requestmanagement/src/components/HoldRequestModal.web";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSwitch(notify:any) {
    switch(notify.attributes.headings) {
      case 'comment':
        return  (t(' commented on your post:')+ ' '+notify.attributes.contents) ;
      case 'like':
        return t(" liked your post");
      case 'join public group':
        return ` joined ${' "'+notify.attributes?.group_name}" group.`;
      default:
        return notify.attributes.headings;
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      
      <>
        <Header type={"notification"} notifyCount={Number(localStorage.getItem("notification_unread_count"))} navigation={this.props.navigation} profileImage={this.state.userInfo.photo} />
        <main>
          <div className="notify-div">
            <Container className="main-notification-container" onClick={this.handleCloseMore}>
              <Paper className="paper-notification-box">
                <Grid className="notify-content-box" container spacing={0}  >
                  <Typography className="notification-title">{t('Notifications')}</Typography>
                  {this.state.notificationData.length > 0 ? this.state.notificationData.map((notify: any,indx: number) => {
                   return (
                        <div key={notify.id} className={notify.attributes.is_read === true ? "notification-box-read": "notification-box-unread"}>
                            <div data-test-id="notify-box-id" onClick={() => {
                              this.handleReadNotification(notify.id)
                              if(notify.attributes.status_type === "hold") {
                                this.setState({ userId: notify.attributes.user_id, groupId: notify.attributes.notificable_id, isHoldRequestOpen: true }, () => {
                                  this.createHoldMessageRequest({ content: "", group_id: this.state.groupId, user_id: this.state.userId })
                                });
                              } else {
                                this.handleNotifyRedirection(notify.attributes.notificable_id, notify.id, notify.attributes.notificable_type)
                              }
                              }}
                            > 
                              <Typography className="notification-content">{notify.attributes.status_type !== "pending" && notify.attributes.status_type !== "accepted" && notify.attributes.status_type !== "hold" && notify.attributes.status_type !== "rejected" && <span className="notify-bold-text">{notify.attributes?.created_by_account_details?.full_name}</span>}
                                <span className="notify-light-text">{this.renderSwitch(notify)}</span>
                              </Typography>
                              <Typography className="notification-time">{notify.attributes.created_at}</Typography>
                            </div>
                            <div data-test-id="more-notify-btn" className={`more-notify-btn-${notify.id}`} onClick={() => this.onMoreModalClickHandler(indx)} style={{cursor: "pointer", position: "relative" }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                                  fill="#64748B" />
                              </svg>
                              {this.state.moreOptionStatus && this.state.moreIndex == indx &&
                                <Box test-id="morebox01" className='more-option-modal'>
                                  {
                                    notify.attributes.is_read === true ?
                                  <Typography data-test-id="unread-id" className='unread-text' onClick={() => this.handleUnreadNotification(notify.id)}>{t('Unread')}</Typography>
                                  :<Typography data-test-id="read-id" className='unread-text' onClick={() => this.handleReadNotification(notify.id)}>{t('Read')}</Typography>
                                  }
                                  </Box>
                              }
                            </div>
                          </div>
                        )
                    }
                  ):<Typography style={{ color: '#475569',textAlign: 'center' }}>
                  {t("No record found")}</Typography>
                }
                </Grid>
              </Paper>
              {this.state.notificationData.length > 0 && <PaginationBlock handlePagination={this.handlePagination} page={this.state.currentPage} totalCount={this.state.totalPage}/>
              }</Container>
            <Snackbar
                style={{ zIndex: 9999 }}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={this.state.showSnackbar}
                autoHideDuration={5000}
                onClose={this.handleCloseSnackbar}
              >
                <MuiAlert
                  onClose={this.handleCloseSnackbar}
                  severity={this.state.severity}
                >
                  {this.state.message}
                </MuiAlert>
              </Snackbar>

              <HoldRequestModal
                open={this.state.isHoldRequestOpen}
                modalCloseFn={this.toggleHoldRequest}
                userInfo={{ user_id: this.state.userId }}
                conversation={this.state.conversation}
                createHoldMessageRequest={this.createHoldMessageRequest}
                groupId={this.state.groupId}
              />
          </div>
        </main>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
