import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  // Customizable Area Start
  Grid,
  Paper,
  Badge,
  TextField,
  // Customizable Area End
  IconButton,
} from "@material-ui/core";

// Customizable Area Start
import "../assets/membersdirectory.scss"
import Header from "../../../components/src/Header.web";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SearchIcon from '@material-ui/icons/Search';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FilterListIcon from '@material-ui/icons/FilterList';
import { user4 } from "./assets";
import { t } from "i18next";
import Dropdown from "./components/Dropdown";
import MemberDirectoryList from "./components/MemberDirectoryList";
import PaginationBlock from "../../../components/src/Pagination.web";
// Customizable Area End

import MemberDirectoryController, {
  Props,
  configJSON,
} from "./MemberDirectoryController";
import LangDropDown from "./components/LangDropdown.web";
import StateDropDown from "./components/StateDropDown.web";

export default class MemberDirectory extends MemberDirectoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div>
        <Header type={"MembersDirectory"} navigation={this.props.navigation}  notifyCount={Number(localStorage.getItem("notification_unread_count"))}/>
        <div style={{ background: "linear-gradient(to bottom, rgba(231,239,252,1) 40%, rgba(255,255,255,1) 100%)", width: '100%', height: '100%' }}>
          <Grid container className="member_directory_page">
            <Grid container spacing={4} style={{ maxWidth: '100%', width: '100%' }}>
              <Grid item xs={12} md={9} lg={9} xl={9} sm={12}>
                <Grid component={Paper} style={{ borderRadius: '8px' }}>
                  <Paper elevation={3} className="members_directory_list" >
                    <Box>
                      <Box className="md_header_box">
                        <Grid>
                          <Typography className="md_header">{t('Directory')}</Typography>
                          <Typography className="md_sub_header">{t('Dedicated to the community growth')}</Typography>
                        </Grid>
                        <Grid container className="members_count_row">
                          <Grid item className="members_count_block" xs={11} sm={11} >
                            <Badge>
                              <PeopleAltIcon
                                style={{
                                  color: '#5497FF',
                                  fontSize: 18
                                }}></PeopleAltIcon>
                            </Badge>
                            <Typography
                              variant="subtitle2"
                              className="member_count"
                            >{t(this.state.membersData?.meta?.total_count)} {t("Members")}</Typography>
                          </Grid>
                          <Grid item container className="search_icon_grid" xs={1} sm={1}>
                            <Badge>
                              {!this.state.searchFeildOpen?<SearchIcon
                                style={webStyle.searchTab} onClick={this.openSearchTab}/>:<TextField data-testid="handleSearchData" value={this.state.searchData} onChange={this.handleSearchData} style={{border:"1px solid #94A3B8", borderRadius:"4px", paddingRight:'5px'}} InputProps={{startAdornment:<SearchIcon
                                  style={{...webStyle.searchTab, marginLeft:'5px', marginRight:"5px"}} onClick={this.openSearchTab}/>}}/>}
                            </Badge>
                          </Grid>
                        </Grid>
                      </Box>
                      <MemberDirectoryList membersData={this.state.membersData?.data} navigation={this.props.navigation} id={""} />
                      {
                        this.state.membersData?.data?.length > 0 &&
                        <PaginationBlock data-test-id="pagination" page={this.state.membersData?.meta?.current_page} totalCount={this.state.membersData?.meta?.total_pages} 
                        handlePagination={this.handlePagination}/>
                      }
                    </Box>
                  </Paper>
                </Grid>

              </Grid>
              <Grid item xs={12} md={3} lg={3} xl={3} sm={12}>
                <Paper elevation={3} className="members_filter_box" >
                  <Box>
                    <Grid container className="filter_clear_grid">
                      <Grid style={{ display: 'flex' }}>
                        <FilterListIcon style={{
                          color: '#475569',
                          fontSize: "24px",
                        }} />
                        <Typography variant="h6" className="filter_text">
                          {t('Filter')}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Button
                          variant="text"
                          className={this.state.selectedCity === '' &&
                            this.state.selectedLanguage === '' &&
                            this.state.selectedCountry === '' &&
                            this.state.selectedRegion === '' &&
                            this.state.selectedState === ''
                           ? "clear_all_text_blur" : "clear_all_text"}
                          onClick={this.clearAllFilters}
                          data-testid='clear_All_Button'
                          disabled={this.state.selectedCity === '' &&
                            this.state.selectedLanguage === '' &&
                            this.state.selectedCountry === '' &&
                            this.state.selectedRegion === '' &&
                            this.state.selectedState === ''
                           }
                        >
                          {t('Clear All')}
                        </Button>
                      </Grid>
                    </Grid>
                    <LangDropDown
                      title={'Working Language'}
                      initialValue={{ attributes: { name: this.state.selectedLanguage } }}
                      options={this.state.workingLanguageArr}
                      onChange={this.setLanguage}
                      placeHolder={'Language'}
                    />
                    <StateDropDown
                      title={'Region'}
                      initialValue={{ name: this.state.selectedRegion }}
                      options={this.state.regionListArr}
                      onChange={this.setRegion}
                      placeHolder={'Region'}
                    />
                    <StateDropDown
                      onChange={this.setCountry}
                      title={'Country'}
                      initialValue={{ name: this.state.selectedCountry }}
                      options={this.state.countryListArr}
                      placeHolder={'Country'}
                    />
                    <StateDropDown
                      title={'State'}
                      initialValue={{ name: this.state.selectedState }}
                      options={this.state.stateListArr}
                      onChange={this.setGivenState}
                      placeHolder={'State'}
                    />
                    <StateDropDown
                      title={'City'}
                      onChange={this.setCity}
                      initialValue={{ name: this.state.selectedCity }}
                      options={this.state.cityListArr}
                      placeHolder={'City'}
                    />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  searchTab:{
    color: '#475569',
    fontSize: 24,
    cursor:"pointer"
  }
};
// Customizable Area End
