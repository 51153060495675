import React from "react";
import { Box } from '@material-ui/core';
import { GroupMediaTabs } from './groupMediaTabs.web';

interface UserMediaProps {
  handleEndPoint: any;
  userMedia: any;
  handleLoadNextAllMedia?: () => void;
  isMoreMediaLoading?: boolean;
}
const UserMedia = (props: UserMediaProps) => {
  return (
    <>
      <Box className="mediaPostss">
        <Box className="recentPostsCardss">
          <GroupMediaTabs groupMediaData={props.userMedia} isMoreMediaLoading={props.isMoreMediaLoading} handleEndPoint={props.handleEndPoint} groupId={1} handlePostDetails={[]} handleLoadNextAllMedia={props.handleLoadNextAllMedia} />
        </Box>
      </Box>
    </>
  );
};

export default UserMedia;
